#privacyText{
  position: relative;
  width: 60%;
  left: 15%;
  margin-top: 3%;
}

#privacyTitleText{
  font-family: Arial;
  font-size: 32px;
}

#privacyBaseText{
  font-family: sans-serif;
  padding-top: 5px;
  font-size: 25px;
}
