#aboutText{
  position: relative;
  width: 60%;
  left: 15%;
  margin-top: 3%;
}

#aboutTitleText{
  font-family: Arial;
  font-size: 32px;
}

#aboutBaseText{
  font-family: sans-serif;
  padding-top: 5px;
  font-size: 25px;
}

#filesHeaderText{
  font-family: sans-serif;
  padding-top: 5px;
  font-size: 25px;
  padding-bottom: 1px;
}

#bulletpoint{
  padding-top: 12px;
  font-size: 20px;
  list-style-type: none;
}

#filesBulletpoints{
  font-family: sans-serif;
  font-size: 22px;
}
