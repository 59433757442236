.Home {
  text-align: center;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background: lightgrey;
  color: white;
}

#upwaveLink{
  position: relative;
  right: 38%;
  height: 90%;
  max-width: 20%;
}

#aboutIcon {
  position: relative;
  width: 60%;
  min-width: 25px;
  min-height: 25px;
}

#searchIcon {
  position: relative;
  width: 60%;
  min-width: 25px;
  min-height: 25px;
}

.nav-Links {
  width: 8%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin-left: 10%;
}

#logo-svg {
  position: relative;
  width: 40%;
  height: 40%;
}

.Foote {
  position: relative;
  margin:0 auto;
  width: 30%;
  bottom: -100px;
}

#subFooter {
  position: relative;
  text-align: center;
  width: 100%;
}

#footerHomeLink {
  display: inline-block;
}

#footerAboutLink {
  display: inline-block;
}

#footerSearchLink {
  display: inline-block;
}

#footerPrivacyLink {
  display: inline-block;
}


/* --Loading Icon-- */
#loadingIcon {
  padding-top: 2%;
  margin-left: 25%;
}

#loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#homeText {
  position: relative;
  width: 60%;
  left: 25%;
  margin-top: 0%;
}

#homeTitleText {
  font-size: 20px;
  color: rgb(0, 112, 192);
  height: 10px;
  padding-top: 20px;
}

#homeBaseText {
  padding-top: 10px;
  font-size: 20px;
  color: rgb(0, 112, 192);
  margin-left: 2%;
}

#homeBaseBottomText {
  padding-top: 30px;
  font-size: 20px;
  color: rgb(0, 112, 192);
}

#filterHeaderText {
  padding-top: 5px;
  font-size: 25px;
  color: rgb(0, 112, 192);
}

#filterBulletpoints {
  list-style-type:decimal;
  font-size: 20px;
  color: rgb(0, 112, 192);
  position: relative;
  margin-top: -2%;
}

#bulletpoint {
  margin-left: 2%;
}

#searchHeaderText {
  padding-top: 5px;
  font-size: 25px;
}

#searchBulletpoints {
  font-family: sans-serif;
  font-size: 20px;
  position: relative;
  margin-top: -2%;
}
