.Search{
  text-align: left;
}

.shareOfSearchString{
  width: 80%;
  white-space: pre;
}

#leftSearchColumn{
  position: absolute;
  background-color: lightgrey;
  width: 22%;
  min-width: 100px;
  /* height: 150%; */
  border-top: solid white;
}

#filters{
  background-color: lightgrey;
  margin-left: 5%;
  width: 90%;
  text-align: left;
  color: rgb(64, 64, 64);
}

#filterBulletpoints{
  position: relative;
  padding-top: 0px;
  top: -5px;
}

#countryList{
  position: relative;
  min-width: 100px;
  background-color: white;
  align-items: center;
  border: solid medium lightgrey;
}

#searchPageTitle{
  font-weight: bold;
  font-size: 25px;
  border-bottom:  lightgrey;
  height: 10%;
}

#sourceText{
  text-align: center;
  font-size: 12px;
}

#countryText{
  font-size: 18px;
  width: 24%;
  height: 12px;
  display: inline-block;
}

#countries{
  min-width: 80px;
}

#categoryText{
  font-size: 18px;
  width: 24%;
  height: 12px;
  display: inline-block;
}

#categories{
  min-width: 80px;
}

#startText{
  font-size: 18px;
  width: 24%;
  height: 12px;
  display: inline-block;
}

#endText{
  font-size: 18px;
  width: 24%;
  height: 12px;
  display: inline-block;
}

#searchListText{
  font-size: 18px;
  width: 24%;
  height: 3px;
  display: inline-block;
}

#infoSearch{
  border: solid grey;
  border-width: thin;
  border-radius: 3px;
  background: white;
  padding: 0px;
  width: 12px;
  font-size: 12px;
  height: 17px;
  text-align: center;
  display: inline-block;
  cursor: default;
  margin-left: 1%;
  color: black;
  font-weight: bold;
}

#infoSearch:hover + #infoSearchText{
  display: flex;
}

#infoSearchText{
  position: absolute;
  padding: 5px;
  left: 100%;
  top: 57.55%;
  width: 80%;
  border: solid black;
  border-width: thin;
  display: none;
  background: white;
  background-position-y: top;
  z-index: 1;
}

#searchListExplanationText{
  font-size: 14px;
  margin-top: -2%;
}

#graphSizeText{
  font-size: 18px;
  width: 28%;
  margin-top: 0px;
  height: 10px;
  display: inline-block;
}

#myRange{
  width: 90%;
}

#multiSubmitInput{
  width: 60%;
  min-width: 70px;
}

#searchingFor{
  width: 90%;
  word-wrap: break-word;
  color: navy;
}

#countries{
  width: 55%;
}

#categories{
  width: 55%;
}

#titleOfChart{
  font-family: Arial;
  font-size: 30px;
  font-weight: bold;
  height:15px;
}

#titleOfBarChart{
  font-family: Arial;
  font-size: 30px;
  font-weight: bold;
  height:15px;
}

#criteriaForChart{
  font-family: Arial;
  font-size: 16px;
  font-weight: lighter;
}

.GraphContainer{
  position: relative;
  margin-left: 25%;
  min-width: 800px;
}

#myBarChart{
  min-width: 500px;
}

#countriesForm{
  position: relative;
  width: 100%;
}

#startDateForm{
}

#endDateForm{
  margin-top:-15px;
}


#dateRange{
  height: 80%;
}

#criteriaSubmitButton{
  height: 40px;
  width: 47%;
  text-align: center;
  font-size: 16px;
  margin-left: 27%;
  font-weight: bold;
}

.chartTitles{
  position: relative;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.chartSubTitles{
  position: relative;
  margin-top: -15px;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
}

#downloadContainer{
  position: relative;
  margin-left: 60%;
  width: 35%;
  text-align: right;
}


#percentageDownloadbutton{
  background-color: rgb(223, 223, 223);
  height: 30px;
  font-weight: normal;
  margin-left: 3%;
  border: solid black;
  border-width: thin;
  padding-left: 25px;
  padding-right: 25px;
}

#percentageDownloadbutton:hover{
  background-color: lightgrey;
}

#volumeDownloadbutton{
  background-color: rgb(223, 223, 223);
  height: 30px;
  font-weight: normal;
  border: solid black;
  border-width: thin;
  padding-left: 25px;
  padding-right: 25px;
}

#volumeDownloadbutton:hover{
  background-color: lightgrey;
}
